exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-listing-tsx": () => import("./../../../src/templates/BlogListing.tsx" /* webpackChunkName: "component---src-templates-blog-listing-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/BlogTag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2020-12-24-new-blog-de-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2020/12/24/new-blog.de.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2020-12-24-new-blog-de-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2020-12-24-new-blog-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2020/12/24/new-blog.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2020-12-24-new-blog-en-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-03-18-private-protected-routes-with-react-router-de-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/03/18/private-protected-routes-with-react-router.de.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-03-18-private-protected-routes-with-react-router-de-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-03-18-private-protected-routes-with-react-router-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/03/18/private-protected-routes-with-react-router.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-03-18-private-protected-routes-with-react-router-en-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-06-05-local-area-network-game-streaming-de-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/06/05/local-area-network-game-streaming.de.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-06-05-local-area-network-game-streaming-de-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-06-05-local-area-network-game-streaming-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/06/05/local-area-network-game-streaming.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-06-05-local-area-network-game-streaming-en-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-06-29-openwrt-on-tplink-archer-a-7-de-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/06/29/openwrt-on-tplink-archer-a7.de.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-06-29-openwrt-on-tplink-archer-a-7-de-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-06-29-openwrt-on-tplink-archer-a-7-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/06/29/openwrt-on-tplink-archer-a7.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-06-29-openwrt-on-tplink-archer-a-7-en-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-08-07-extend-wlan-with-a-openwrt-router-de-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/08/07/extend-wlan-with-a-openwrt-router.de.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-08-07-extend-wlan-with-a-openwrt-router-de-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-08-07-extend-wlan-with-a-openwrt-router-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/08/07/extend-wlan-with-a-openwrt-router.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-08-07-extend-wlan-with-a-openwrt-router-en-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-08-14-integrate-zigbee-devises-via-sonoff-into-home-assistant-de-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/08/14/integrate-zigbee-devises-via-sonoff-into-home-assistant.de.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-08-14-integrate-zigbee-devises-via-sonoff-into-home-assistant-de-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-08-14-integrate-zigbee-devises-via-sonoff-into-home-assistant-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/08/14/integrate-zigbee-devises-via-sonoff-into-home-assistant.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-08-14-integrate-zigbee-devises-via-sonoff-into-home-assistant-en-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-11-13-my-workstation-setup-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2021/11/13/my-workstation-setup.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2021-11-13-my-workstation-setup-en-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2022-08-02-integrate-raspbee-2-into-home-assistant-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2022/08/02/integrate-raspbee-2-into-home-assistant.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2022-08-02-integrate-raspbee-2-into-home-assistant-en-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2022-08-05-set-up-wireguard-on-openwrt-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2022/08/05/set-up-wireguard-on-openwrt.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2022-08-05-set-up-wireguard-on-openwrt-en-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-data-blog-2022-08-20-time-tracking-template-for-google-sheets-en-mdx": () => import("./../../../src/templates/Blog.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/blog/2022/08/20/time-tracking-template-for-google-sheets.en.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-data-blog-2022-08-20-time-tracking-template-for-google-sheets-en-mdx" */),
  "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-data-protection-de-mdx": () => import("./../../../src/templates/GenericPage.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/pages/data-protection.de.mdx" /* webpackChunkName: "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-data-protection-de-mdx" */),
  "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-data-protection-en-mdx": () => import("./../../../src/templates/GenericPage.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/pages/data-protection.en.mdx" /* webpackChunkName: "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-data-protection-en-mdx" */),
  "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-imprint-de-mdx": () => import("./../../../src/templates/GenericPage.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/pages/imprint.de.mdx" /* webpackChunkName: "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-imprint-de-mdx" */),
  "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-imprint-en-mdx": () => import("./../../../src/templates/GenericPage.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/pages/imprint.en.mdx" /* webpackChunkName: "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-imprint-en-mdx" */),
  "component---src-templates-generic-page-with-aside-tsx-content-file-path-content-data-pages-person-de-mdx": () => import("./../../../src/templates/GenericPageWithAside.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/pages/person.de.mdx" /* webpackChunkName: "component---src-templates-generic-page-with-aside-tsx-content-file-path-content-data-pages-person-de-mdx" */),
  "component---src-templates-generic-page-with-aside-tsx-content-file-path-content-data-pages-person-en-mdx": () => import("./../../../src/templates/GenericPageWithAside.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/pages/person.en.mdx" /* webpackChunkName: "component---src-templates-generic-page-with-aside-tsx-content-file-path-content-data-pages-person-en-mdx" */),
  "component---src-templates-media-landing-tsx": () => import("./../../../src/templates/MediaLanding.tsx" /* webpackChunkName: "component---src-templates-media-landing-tsx" */),
  "component---src-templates-media-listing-tsx": () => import("./../../../src/templates/MediaListing.tsx" /* webpackChunkName: "component---src-templates-media-listing-tsx" */),
  "component---src-templates-media-tsx": () => import("./../../../src/templates/Media.tsx" /* webpackChunkName: "component---src-templates-media-tsx" */),
  "component---src-templates-project-listing-tsx": () => import("./../../../src/templates/ProjectListing.tsx" /* webpackChunkName: "component---src-templates-project-listing-tsx" */),
  "component---src-templates-project-tsx-content-file-path-content-data-projects-gatsby-plugin-i-18-n-l-10-n-index-de-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/projects/gatsby-plugin-i18n-l10n/index.de.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-data-projects-gatsby-plugin-i-18-n-l-10-n-index-de-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-data-projects-gatsby-plugin-i-18-n-l-10-n-index-en-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/projects/gatsby-plugin-i18n-l10n/index.en.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-data-projects-gatsby-plugin-i-18-n-l-10-n-index-en-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-data-projects-timelet-index-de-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/projects/timelet/index.de.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-data-projects-timelet-index-de-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-data-projects-timelet-index-en-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/r.obin.ch/r.obin.ch/content/data/projects/timelet/index.en.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-data-projects-timelet-index-en-mdx" */)
}

